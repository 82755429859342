<template>
  <div>
    <Menu />
    <div id="newsDeails">
      <div class="NewsDetail">
        <img src="../assets/images/newsBanner.png" class="banner w100" />
        <div class="content bg_fff nContent">
          <!-- crumbs -->
          <div class="crumbs">
            <div class="content d-flex d-flex-middle">
              <p class="color9 fontSize12">当前所在位置：</p>
              <router-link to="/" class="color9 fontSize12">首页</router-link>
              <p class="color9 fontSize12 and">></p>
              <p class="color fontSize12">{{detailsData.News_Title}}</p>
            </div>
          </div>
          <!--  -->
          <div class="ndContent">
            <p class="nd_title">{{detailsData.News_Title}}</p>
            <div class="d-flex d-flex-middle d-flex-center">
              <p class="color9 fontSize12">来源: {{detailsData.News_Source}}</p>
              <p class="color9 fontSize12">时间：{{detailsData.News_ReleaseDate}}</p>
            </div>
            <div class="nd_txt" v-html="detailsData.News_Content">
              
            </div>
            <div class="change d-flex d-flex-middle d-flex-between">
              <div class="d-flex d-flex-middle" v-if="prevData.News_Id">
                <p class="color9 fontSize12">上一篇：</p>
                <router-link :to="'/NewsDetail/' + prevData.News_Id">{{prevData.News_Title}}</router-link>
              </div>
              <div class="d-flex d-flex-middle d-flex-end" v-if="nextData.News_Id">
                <p class="color9 fontSize12">下一篇：</p>
                <router-link :to="'/NewsDetail/' + nextData.News_Id">{{nextData.News_Title}}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot />
    <RightGuide />
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Foot from "@/components/Foot";
import RightGuide from "@/components/RightGuide";
import { newsById } from "@/api/app";
export default {
  components: {
    Menu, Foot, RightGuide
  },
  data() {
    return {
      newId: '',
      detailsData: {},
      nextData: "",
      prevData: "",
    }
  },
    watch: {
    $route(to, from) {
      // 判断跳转的路径是否你要监听的路径，
      if (to.path.includes('/NewsDetail')) {
        this.newId = to.params.id
        this.getNewsById()
      }
    }
  },
  mounted() {
    this.newId = this.$route.params.id;
    this.getNewsById()
  },
  methods: {

    changePages(prevData){
      this.$router.replace({ path:`/NewsDetail/${prevData.News_Id}`})
    },

    getNewsById() {
      newsById({ news_Id: this.newId }).then((res) => {
        if (res.status) {
          this.detailsData = res.data
          this.nextData = res.data.next
          this.prevData = res.data.prev
        }
      })

    },
  }
}
</script>

<style lang="scss">
body {
  background-color: #fafafa;
}
#newsDeails {
  /*  */

  /*  */
  .NewsDetail {
    position: relative;
    box-sizing: border-box;
    padding-top: 270px;
    min-height: 800px;
    padding-bottom: 100px;
  }
  .banner {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .nContent {
    position: relative;
    z-index: 22;
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0 40px;
  }
  /*  */
  .ndContent {
    margin-top: 50px;
  }
  .nd_title {
    text-align: center;
    font-size: 24px;
    color: #333;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .nd_title + div {
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
  }
  .nd_title + div p {
    margin: 0 20px;
  }
  .nd_txt {
    box-sizing: border-box;
    padding: 60px 0 30px 0;
    border-bottom: 1px solid #eee;
    min-height: 500px;
  }
  .nd_txt p {
    color: #333;
    font-size: 14px;
    line-height: 24px;
    text-indent: 2em;
    margin-bottom: 10px;
  }
  .change {
    height: 70px;
  }
  .change a {
    margin: 0 10px;
    color: #0087ed;
  }
}
</style>